import { Typography } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import { Text } from "./styles"

const Agenda = ({ assignmentActionItems, hasIncompleteAssignments, studentsWithIncompleteAssignments }) => {
  return (
    <Text variant="body2" component="div" gutterBottom sx={{ textTransform: "capitalize" }}>
      <strong>Agenda: </strong> {assignmentActionItems.agenda}
      {hasIncompleteAssignments && (
        <>
          &nbsp;with&nbsp;
          <Typography variant="body2" aria-label="Students with incomplete assignments">
            {studentsWithIncompleteAssignments.map(({ first_name, last_name }, index) => (
              <span key={`${first_name}-${last_name}-${index}`}>
                {`${first_name} ${last_name}`}
                {index < studentsWithIncompleteAssignments.length - 1 ? ", " : "."}
              </span>
            ))}
          </Typography>
        </>
      )}
    </Text>
  )
}

Agenda.propTypes = {
  assignmentActionItems: PropTypes.shape({
    agenda: PropTypes.string.isRequired,
    action_item_link: PropTypes.string,
    action_item: PropTypes.string,
  }).isRequired,
  hasIncompleteAssignments: PropTypes.bool.isRequired,
  studentsWithIncompleteAssignments: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    })
  ).isRequired,
}

export default React.memo(Agenda)
