import React, { useContext } from "react"
import CollapsibleTable from "./collapsible_table"
import useFetchScheduleProgram from "./hooks/useFetchScheduleProgram"
import { DataContext } from "@/contexts"
import LoadingSpinner from "@/common/loading"

const CurriculumPage = () => {
  const { schedule } = useContext(DataContext)
  const { id: scheduleId } = schedule
  const { program, loading } = useFetchScheduleProgram({ scheduleId })

  if (loading) return <LoadingSpinner />

  return (
    <div className="card px-4 py-4 session-information">
      <h1 className="font-weight-semibold mb-0 flex-grow-1">{program?.name || ""}</h1>
      <h6 className="font-size-medium font-weight-semibold extra-dark-gray">{program?.description || ""}</h6>

      <hr className="divider fullwidth soft-color" />
      <div className="d-flex ">
        <div className="w-100">
          <CollapsibleTable
            programLevels={program?.program_levels || []}
            preAssessments={program?.pre_assessments || []}
          />
        </div>
      </div>
    </div>
  )
}

export default CurriculumPage
