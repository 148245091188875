import BRTThemeProvider from "@/providers/theme"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import styled from "styled-components"
import LessonsList from "./lessons_list"
import { Divider, Stack, Link } from "@mui/material"
import DescriptionIcon from "@mui/icons-material/Description"

const StyledAccordion = styled(Accordion)`
  & .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px;
  }

  .MuiAccordionSummary-root {
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
      background-color: #e8e8e8;
    }
  }

  .MuiAccordionDetails-root {
    padding: 12px;
    background-color: #ffffff;
  }
`

const CollapsibleTable = ({ programLevels, preAssessments }) => {
  const rows = useMemo(() => [...preAssessments, ...programLevels], [preAssessments, programLevels])

  return (
    <BRTThemeProvider>
      {rows.map(row => (
        <StyledAccordion key={row.name}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "600" }}>{row.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {row?.lessons?.length ? <LessonsList lessons={row.lessons} /> : <PreAssessmentRow level={row} />}
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </BRTThemeProvider>
  )
}

const PreAssessmentRow = ({ level }) => (
  <Stack spacing={2} divider={<Divider />}>
    <Stack key={level.name} spacing={1} sx={{ mx: "auto", p: 1 }}>
      <Typography component="p" variant="body2" color="text.primary">
        {level.description}
      </Typography>
      <Stack>
        {level && (
          <Stack direction="row" spacing={1}>
            <Link
              key={level.id}
              href={level.url}
              variant="body2"
              target="_blank"
              rel="noreferrer"
              sx={{
                textWrap: "balance",
              }}
            >
              <DescriptionIcon
                sx={{
                  color: "#3c00f5",
                }}
              />
              {level.type || level.name}
            </Link>
          </Stack>
        )}
      </Stack>
    </Stack>
  </Stack>
)

CollapsibleTable.propTypes = {
  pre_assessments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  programLevels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      lessons: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string,
          description: PropTypes.string,
          lesson_resources: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              name: PropTypes.string,
              resource_type: PropTypes.string.isRequired,
              resource_url: PropTypes.string.isRequired,
              show_icon: PropTypes.bool,
            })
          ),
        })
      ),
    })
  ).isRequired,
}

export default CollapsibleTable
