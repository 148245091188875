import { Divider, Link, Stack, Typography } from "@mui/material"
import PropTypes from "prop-types"
import React, { useMemo } from "react"

const humanizeString = str => {
  let i,
    frags = str.split("_")
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
  }
  return frags.join(" ")
}

const LinkItem = ({ lessonResource }) => (
  <Link
    key={lessonResource.id}
    href={lessonResource.resource_url}
    variant="body2"
    target="_blank"
    rel="noreferrer"
    sx={{
      textWrap: "balance",
    }}
  >
    {humanizeString(lessonResource.name || "") || humanizeString(lessonResource.resource_type || "")}
  </Link>
)

LinkItem.propTypes = {
  lessonResource: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    lessonResource: PropTypes.object,
    show_icon: PropTypes.bool,
  }),
}

const LessonsList = ({ lessons = [] }) => {
  const renderedLessons = useMemo(() => {
    return lessons.map((lesson, i) => (
      <Stack key={i} spacing={1} sx={{ mx: "auto", p: 1 }}>
        <Typography variant="h6" className="font-weight-bold">
          {lesson.name}
        </Typography>
        <Typography component="p" variant="body2" color="text.primary">
          {lesson.description}
        </Typography>
        <Stack>
          <Typography component="span" variant="body2" color="text.primary">
            <span className="font-weight-bold">Resources: </span>
          </Typography>
          <Stack direction="row" spacing={1}>
            {lesson?.lesson_resources?.map(lessonResource => (
              <LinkItem id={lessonResource.id} lessonResource={lessonResource} key={lessonResource.id} />
            ))}

            {lesson && lesson.url && (
              <LinkItem
                id={lesson.id}
                lessonResource={{
                  id: lesson.id,
                  name: "Activity",
                  resource_url: lesson.url,
                }}
                key={lesson.id}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    ))
  }, [lessons])

  return (
    <Stack spacing={2} divider={<Divider />}>
      {renderedLessons}
    </Stack>
  )
}

LessonsList.propTypes = {
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      lesson_number: PropTypes.number,
      lesson_resources: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string,
          resource_type: PropTypes.string.isRequired,
          resource_url: PropTypes.string.isRequired,
          show_icon: PropTypes.bool,
        })
      ).isRequired,
    })
  ),
}

export default React.memo(LessonsList)
