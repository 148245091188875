import { Typography } from "@mui/material"
import PropTypes from "prop-types"
import React from "react"
import { Text } from "./styles"

const ActionItem = ({ assignmentActionItems }) => {
  return (
    <Text variant="body2" component="div">
      <strong>Action Item: </strong>
      {assignmentActionItems.action_item_link ? (
        <Typography
          component="a"
          variant="body2"
          href={assignmentActionItems.action_item_link}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="Action item link"
        >
          {assignmentActionItems.action_item}
        </Typography>
      ) : (
        assignmentActionItems.action_item
      )}
    </Text>
  )
}

ActionItem.propTypes = {
  assignmentActionItems: PropTypes.shape({
    action_item_link: PropTypes.string,
    action_item: PropTypes.string.isRequired,
    agenda: PropTypes.string.isRequired,
  }).isRequired,
}

export default React.memo(ActionItem)
