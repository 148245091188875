import NavigationMenu from "@/components/blocks/NavigationMenu"
import { UpcomingSession } from "@/components/UpcomingSession"
import { DataContext, InitialLoadingContext } from "@/contexts"
import { className } from "@/helpers/className"
import useViewport from "@/hooks/useViewport"
import moment from "moment"
import React, { useContext, useMemo } from "react"
import { Col, Row } from "react-bootstrap"
import { Outlet } from "react-router-dom"
import useFetchScheduleProgram from "../../opening_module/pages/curriculum/hooks/useFetchScheduleProgram"
import { tabsNavigationConfig } from "../configurations/routing"
import { useScheduleModuleLinkBuilder } from "../hooks/link_builder"
import Dashboard from "./dashboard"

const PageOutlet = ({ currentUser }) => {
  const { schedule } = useContext(DataContext)
  const { id: scheduleId } = schedule || { id: "" }
  const { program } = useFetchScheduleProgram({ scheduleId })
  const isCurriculumProgram = program?.is_curriculum_program || false
  const { feature_flags } = currentUser
  const { initialLoading } = useContext(InitialLoadingContext)
  const { isMobileViewport } = useViewport()
  const classNames = useMemo(
    () => ({
      spinner: className(
        "d-flex",
        "align-items-center",
        "justify-content-center",
        isMobileViewport ? "min-h-200-px" : "min-h-600-px"
      ),
    }),
    [isMobileViewport]
  )

  const defaultNavigationParams = useMemo(() => ({ report_date: moment().format("YYYY-MM-DD") }), [])

  if (initialLoading) {
    return (
      <div className={classNames.spinner}>
        <div className="spinner-border text-primary" />
      </div>
    )
  }

  const canShowNextSession =
    feature_flags["curriculum_enabled"] &&
    ["admin", "tutor"].includes(currentUser.role) &&
    program?.is_curriculum_program &&
    schedule?.upcoming_session

  return (
    <div className="container p-0 d-flex flex-column flex-grow-1">
      <Row className="entity-outlet">
        <Col className="p-0">
          <div className="container users-navbar_container -white">
            <h2 className="users-navbar_title m-0">
              {schedule.educatable?.name}, {schedule.school?.name}
            </h2>
          </div>
          <div className="container users-navbar_container -white align-items-start">
            <NavigationMenu
              configs={tabsNavigationConfig(currentUser, isCurriculumProgram)}
              defaultParams={defaultNavigationParams}
              useLinkBuilder={useScheduleModuleLinkBuilder}
            />
          </div>
          <div className="container mt-4">
            {canShowNextSession && <UpcomingSession upcomingSession={schedule?.upcoming_session} />}
            <div className="entity-outlet_side-wrapper mt-4 mx-n1 px-2">
              <aside className="entity-outlet_side -info">
                <div className="position-sticky mb-4" style={{ top: 20 }}>
                  <Dashboard />
                </div>
              </aside>
              <main className="entity-outlet_side -content mx-1 mb-4">
                <Outlet />
              </main>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default PageOutlet
