import { MeetingFormats } from "@/constants"
import TimerSharpIcon from "@mui/icons-material/TimerSharp"
import { Typography } from "@mui/material"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { SessionAction } from "./SessionAction"
import { NextSessionContainer, SessionDetails, SessionHeader } from "./styles"
import { mapSessionData } from "./utils"

import ActionItem from "./ActionItem"
import Agenda from "./Agenda"
import Headline from "./Headline"

export const UpcomingSession = React.memo(({ upcomingSession }) => {
  const sessionData = upcomingSession && mapSessionData(upcomingSession)

  const onJoinClassClick = useCallback(
    e => {
      e.preventDefault()
      if (!sessionData) return

      const { location, address, scheduleId } = sessionData

      if (location === MeetingFormats.Online) {
        window.open(address, "_blank")
      } else if (location === MeetingFormats.LessonSpace && scheduleId) {
        window.open(`/tutor/dashboard/classroom/${scheduleId}`, "_blank")
      }
    },
    [sessionData]
  )

  if (!upcomingSession || !sessionData?.assignmentActionItems) {
    return null
  }

  const { canStartNextSession, isOfflineSession } = sessionData

  return (
    <NextSessionContainer>
      <SessionDetails>
        <SessionHeader>
          <TimerSharpIcon sx={{ color: "rgb(240, 35, 14)" }} aria-label="Up next icon" />
          <Typography variant="h6" component="div" color="textPrimary">
            Up next
          </Typography>
        </SessionHeader>
        <Headline {...sessionData} />
        <Agenda {...sessionData} />
        <ActionItem {...sessionData} />
      </SessionDetails>
      {!isOfflineSession && (
        <SessionAction canStartNextSession={canStartNextSession} onJoinClassClick={onJoinClassClick} />
      )}
    </NextSessionContainer>
  )
})

UpcomingSession.propTypes = {
  upcomingSession: PropTypes.object.isRequired,
}

UpcomingSession.displayName = "UpcomingSession"
