import { MeetingFormats } from "@/constants"
import { formatDateKeepZone } from "@/helpers/dates"
import { isAfter, subMinutes } from "date-fns"

export const getScheduleUrl = (scheduleId, sessionDate) => {
  const formattedDate = formatDateKeepZone(sessionDate)
  return `/schedules/${scheduleId}?month=${formattedDate}`
}

// Mapping function to transform the session data
export const mapSessionData = nextSessionData => {
  if (!nextSessionData || !Object.entries(nextSessionData).length) return null

  const {
    address,
    assignment_action_items: assignmentActionItems,
    formatted_scheduled_at_text: sessionStartsIn,
    location,
    program_name: programName,
    scheduled_at: sessionScheduledAt,
    schedule_id: scheduleId,
    students,
    students_with_incomplete_assignment: studentsWithIncompleteAssignments,
  } = nextSessionData

  const currentTime = new Date()
  const sessionTime = new Date(sessionScheduledAt)
  const canStartNextSession = isAfter(currentTime, subMinutes(sessionTime, 5))
  const isOfflineSession = location === MeetingFormats.Offline
  const hasIncompleteAssignments = studentsWithIncompleteAssignments?.length > 0

  return {
    address,
    assignmentActionItems,
    sessionStartsIn,
    location,
    programName,
    sessionScheduledAt,
    scheduleId,
    students,
    studentsWithIncompleteAssignments,
    canStartNextSession,
    isOfflineSession,
    hasIncompleteAssignments,
  }
}
