import apiClient from "@/common/apiClient"
import LoadingSpinner from "@/common/loading"
import { colors } from "@/library/designTokens"
import React, { useCallback, useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"

const AssignmentIframe = styled.iframe`
  width: 100%;
  height: 80vh;
  border: none;
`

const ErrorMessage = styled.div`
  color: ${colors.error};
`

export const StudentAssignmentsPage = ({ currentUser, programName, studentName }) => {
  const userRole = currentUser?.role
  const [loading, setLoading] = useState(true)
  const [assessmentLink, setAssessmentLink] = useState(null)
  const [error, setError] = useState(null)
  const pathParts = window.location.pathname.split("/")
  const id = pathParts[pathParts.length - 1]

  const fetchAssessmentLink = useCallback(async () => {
    try {
      let response
      if (userRole === "tutor" || userRole === "admin") {
        response = await apiClient.get(`/api/v2/student_assignment/${id}/assignment_link`)
      } else if (userRole === "student") {
        response = await apiClient.get(`/api/v2/students/assignment_link?student_assignment_id=${id}`)
      }
      setAssessmentLink(response?.data?.assignment_link)
    } catch (err) {
      setError(err)
    } finally {
      setLoading(false)
    }
  }, [id, userRole])

  useEffect(() => {
    fetchAssessmentLink()
  }, [fetchAssessmentLink, userRole])

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <Container>
        <Row>
          <Col>
            <ErrorMessage>Error loading assignment: {error.message}</ErrorMessage>
          </Col>
        </Row>
      </Container>
    )
  }

  if (!assessmentLink) {
    return (
      <Container>
        <Row>
          <Col>
            <ErrorMessage>No Assignments found</ErrorMessage>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container fluid className="p-0 d-flex flex-column flex-grow-1">
      <Row>
        <Col className="p-0" style={{ flexGrow: 2 }}>
          <Container className="tutor-dashboard-content">
            <Row>
              <Col>
                <h1 className="student-dashboard-header pl-2">{studentName}</h1>
                <h5 className="student-dashboard-header mb-2 pl-2">{programName}</h5>
                <AssignmentIframe src={assessmentLink} title="Ann Edu Activities" />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default StudentAssignmentsPage
